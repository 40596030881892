import { MissionComparableDto } from '@/shared/dtos/mission-comparable.dto';
import { MissionDto } from '@/shared/dtos/mission.dto';
import axios from 'axios';
import { RechercheMissionDto } from '@/shared/dtos/recherche-mission.dto';
import { BaseDtoApiService } from './base-dto-api.service';
import { AnnexeDto } from '@/shared/dtos/annexe.dto';
import moment from 'moment';
import { MergeDto } from '@/shared/dtos/merge.dto';

export class MissionApiService extends BaseDtoApiService<MissionDto> {
  constructor() {
    super('missions');
  }

  public getByOmereContactId(omereContactId: number, dateModiticationUtcPrestationsExpertises: any): Promise<MissionDto[]> {
    return axios
      .post(`${this._endpoint}/contact/${omereContactId}`, dateModiticationUtcPrestationsExpertises)
      .then((data: any) => this.convertDatas(data));
  }

  public getAControlerByOmereContactId(omereContactId: number): Promise<MissionDto[]> {
    return axios.get(`${this._endpoint}/controle/${omereContactId}`).then((data: any) => this.convertDatas(data));
  }

  public getTypePrestationExpertiseByMissionId(omereMissionId: number): Promise<string> {
    return axios.get(`${this._endpoint}/${omereMissionId}/typeprestationexpertise`).then((response: any) => {
      return response.data.result;
    });
  }

  public updateGeolocalisation(mission: MissionDto): Promise<MissionDto> {
    return axios.put(`${this._endpoint}/${mission.id}/prestationexpertise/${mission.prestationExpertise.id}/geolocalisation`, {
      typePrestationExpertise: mission.typePrestationExpertise,
      adresse: mission.prestationExpertise.geographiqueAdresse,
      latitude: mission.prestationExpertise.geographiqueLatitude,
      longitude: mission.prestationExpertise.geographiqueLongitude,
    });
  }

  public updateExpertiseFromReference(mission: MissionDto, fromReference: string): Promise<MissionDto> {
    return axios
      .put(`${this._endpoint}/${mission.id}/prestationexpertise/${mission.prestationExpertise.id}/fromreference`, {
        typePrestationExpertise: mission.typePrestationExpertise,
        reference: fromReference.replace('/', '_'),
      })
      .then((data: any) => this.convertData(data));
  }

  public search(recherche: RechercheMissionDto): Promise<any> {
    return axios.post(`${this._endpoint}/search`, recherche).then((datas: any) => this.convertDatas(datas));
  }

  public getComparables(id: number): Promise<MissionComparableDto[]> {
    return axios.get(`${this._endpoint}/${id}/comparables/`).then((response: any) => {
      return response.data.result;
    });
  }

  public getAnnexes(id: number): Promise<AnnexeDto[]> {
    return axios.get(`${this._endpoint}/${id}/annexes/`).then((response: any) => {
      return response.data.result;
    });
  }

  public updateDateVisite(id: number, dateVisite: Date): Promise<void> {
    const formData = new FormData();
    formData.append('dateVisite', dateVisite ? moment(dateVisite).toISOString() : '');
    return axios.put(`${this._endpoint}/${id}/datevisite`, formData);
  }

  public annulerRDV(id: number, message: string): Promise<void> {
    const formData = new FormData();
    formData.append('message', message);
    return axios.put(`${this._endpoint}/${id}/annulerRDV`, formData);
  }

  public getMerge(id: number, mission: MissionDto): Promise<MergeDto[]> {
    return axios.post(`${this._endpoint}/${id}/merge/`, mission).then((response: any) => {
      return response.data.result;
    });
  }

  public changeTypePrestationExpertise(id: number, oldTypePrestationExpertise: number, newTypePrestationExpertise: number): Promise<void> {
    return axios.put(`${this._endpoint}/${id}/typeprestationexpertise/from/${oldTypePrestationExpertise}/to/${newTypePrestationExpertise}`);
  }
}
