

























































































































import { Vue, Component, Mixins, Watch } from 'vue-property-decorator';
import RapportExpertiseSectionMixin from '@/mixins/rapport-expertise-section.mixin';

import VisaDisplay from '@/shared/components/visa-display.vue';
import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import RadioBoolean from '@/shared/components/radio-boolean.vue';
import ChoicePopup from '@/shared/components/choice-popup.vue';
import { DonneesEstimationImmobiliereUpdateInputDto } from '@/shared/dtos/donnees-estimation-immobiliere-update-input.dto';
import { isNullOrEmpty } from '@/shared/utilities/string.utility';
import RapportExpertiseDpe from '@/shared/components/rapport-expertise/rapport-expertise-dpe.vue';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';

@Component({
  name: 'PrestationExpertiseRapportDescriptionParticuliereBatiment',
  components: {
    VisaDisplay,
    RapportExpertiseFileUploader,
    RadioBoolean,
    ChoicePopup,
    RapportExpertiseDpe,
  },
})
export default class PrestationExpertiseRapportDescriptionParticuliereBatiment extends Mixins(RapportExpertiseSectionMixin) {
  //#region LIFE CYCLES

  //#endregion

  //#region COMPUTED

  //#endregion

  //#region WATCH
  @Watch('typeDeBien')
  public typeDeBienChanged(newVal: any): void {
    if (this.isTypeDeBienAppartement) {
      this.rapport.caracteristiqueAssainissementId = null;
      this.rapport.caracteristiquesMitoyennete = null;
      this.rapport.caracteristiquesMitoyenneteCoteId = null;
    }

    // on clean les équipements & agréments non-valides au changement de type de bien
    for (let i = 0; i < this.rapport.prestationsEquipements.length; i++) {
      let equipement = this.businessEntries.prestationsEquipements.filter(
        (e: any) => e.id == this.rapport.prestationsEquipements[i].businessEntrySelectedId
      )[0];

      if (equipement.categorieActifType != 0 && equipement.categorieActifType != newVal) this.rapport.prestationsEquipements.splice(i--, 1);
    }

    for (let j = 0; j < this.rapport.prestationsAgrements.length; j++) {
      let agrement = this.businessEntries.prestationsAgrements.filter(
        (e: any) => e.id == this.rapport.prestationsAgrements[j].businessEntrySelectedId
      )[0];

      if (agrement.categorieActifType != 0 && agrement.categorieActifType != newVal) this.rapport.prestationsAgrements.splice(j--, 1);
    }
  }

  @Watch('rapport.caracteristiquesMitoyennete')
  public caracteristiquesMitoyenneteChanged(value: boolean): void {
    if (value === false) this.rapport.caracteristiquesMitoyenneteCoteId = null;
  }

  //#endregion

  //#region EVENTS
  public async onCaracteristiquesAnneeConstructionChange(value: any) {
    if (this.isMobile || isNullOrEmpty(value)) return;
    var input = new DonneesEstimationImmobiliereUpdateInputDto();
    input.buildingYear = value;
    await Vue.prototype.$rapportExpertiseApiService.updateDonneesEstimationImmobiliere(this.rapport.id, input);
  }

  public onAnnexeDpeEmit(value: any): void {
    const annexeDPE = this.rapport.annexeEnvironnements.find((x: any) => x.businessEntrySelectedId === BusinessEntryEnum.DPE);
    if (annexeDPE) annexeDPE.businessEntryQualificationId = value;
  }

  //#endregion

  //#region FUNCTIONS

  //#endregion
}
