import { AnnexeStatutEnum } from './../shared/enums/annexe-statut.enum';
import { EvaluationMethodeRetenueEnum } from './../shared/enums/evaluation-methode-retenue.enum';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { ContratTypeVisiteEnum } from '@/shared/enums/contrat-type-visite.enum';
import { RapportExpertiseBusinessEntryRelationDto } from '@/shared/dtos/rapport-expertise-business-entry-relation.dto';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';

const isElementComparaisonTransaction = function (elementComparaison: any): boolean {
  return elementComparaison.typeDeSourceId === 2016001;
};

const isElementComparaisonValorisation = function (elementComparaison: any): boolean {
  return elementComparaison.typeDeSourceId === 2016004;
};

const isDistributionInterieureSurfaceTypeAutre = function (surface: any): boolean {
  return surface.typeBusinessItemId === 2036900;
};

const isDistributionInterieureEtageTypeAutre = function (etage: any): boolean {
  return etage.typeBusinessItemId === 2037900;
};

const isContratTypeVisiteInterieurEtExterieur = function (contratTypeVisiteId: any): boolean {
  return contratTypeVisiteId === ContratTypeVisiteEnum.InterieurEtExterieur;
};

const isContratTypeVisiteExterieur = function (contratTypeVisiteId: any): boolean {
  return contratTypeVisiteId === ContratTypeVisiteEnum.ExterieurUniquement;
};

const isContratTypeVisiteSurPieces = function (contratTypeVisiteId: any): boolean {
  return contratTypeVisiteId === ContratTypeVisiteEnum.SurPieces;
};

const isPrestationsSurfaceAnnexeTypeAutre = function (prestationsSurfaceAnnexe: any): boolean {
  return prestationsSurfaceAnnexe.typeBusinessItemId === 2038900;
};

const isPrestationsDependanceTypeAutre = function (prestationsDependance: any): boolean {
  return prestationsDependance.typeBusinessItemId === 2041900;
};

const isEvaluationMethodeRetenueComparaison = function (evaluationMethodeRetenueId: number): boolean {
  return evaluationMethodeRetenueId === EvaluationMethodeRetenueEnum.Comparaison;
};

const isEvaluationMethodeRetenueComparaisonEtRendement = function (evaluationMethodeRetenueId: number): boolean {
  return evaluationMethodeRetenueId === EvaluationMethodeRetenueEnum.ComparaisonEtRendement;
};

const isEvaluationMethodeRetenueRendement = function (evaluationMethodeRetenueId: number): boolean {
  return evaluationMethodeRetenueId === EvaluationMethodeRetenueEnum.Rendement;
};

const hasValeurApresTravaux = function (mission: MissionDto): boolean {
  return mission.hasValeurApresTravaux === true;
};

const hasValeurLocative = function (mission: MissionDto): boolean {
  return mission.hasValeurLocative === true;
};

const isSourceDesSurfacesActeNotarie = function (sourceDesSurfacesId: number): boolean {
  return sourceDesSurfacesId === 2042004;
};

const isContratConditionOccupationOccupe = function (contratConditionOccupationId: number): boolean {
  return contratConditionOccupationId === 2046002;
};

const isFiscaleSourceTaxeFonciereDocumentConsulte = function (fiscaleSourceTaxeFonciereId: number): boolean {
  return fiscaleSourceTaxeFonciereId === 2015002;
};

const isContratTypePrestationNotCertificatExpertise = function (contratTypePrestationId: number): boolean {
  return contratTypePrestationId !== 2045018;
};

const contratTypePrestationColorDefault = '#00ab9d';
const contratTypePrestationRapportExpertise = '#35a2eb';
const contratTypePrestationCertificatExpertise = contratTypePrestationColorDefault;

const contratTypePrestationColor = function (rapport: any) {
  if (!rapport) return contratTypePrestationColorDefault;
  return isContratTypePrestationNotCertificatExpertise(rapport.contratTypePrestationId)
    ? contratTypePrestationRapportExpertise
    : contratTypePrestationCertificatExpertise;
};

const hasOptionFaciliteDeNegociationESFI = function (mission: MissionDto): boolean {
  return mission.hasOptionFaciliteDeNegociationESFI === true;
};

const isRapportVersionGreaterOrEqualsTo = function (rapport: any, version: number): boolean {
  return rapport.version && rapport.version >= version;
};

const isRapportVersionLowerOrEqualsTo = function (rapport: any, version: number): boolean {
  return rapport.version && rapport.version <= version;
};

const areBusinessEntryRelationsValid = function (businessEntries: RapportExpertiseBusinessEntryRelationDto[]): boolean {
  return businessEntries.length && !businessEntries.some((x) => x.businessEntryQualificationId === null);
};

const isBusinessEntryDPEValid = function (businessEntries: RapportExpertiseBusinessEntryRelationDto[]): boolean {
  const annexeDPE = businessEntries.find((x: any) => x.businessEntrySelectedId === BusinessEntryEnum.DPE);
  return (
    annexeDPE !== null &&
    (annexeDPE.businessEntryQualificationId === null || annexeDPE.businessEntryQualificationId === AnnexeStatutEnum.Obtenu)
  );
};

export {
  isElementComparaisonTransaction,
  isElementComparaisonValorisation,
  isDistributionInterieureSurfaceTypeAutre,
  isDistributionInterieureEtageTypeAutre,
  isContratTypeVisiteInterieurEtExterieur,
  isContratTypeVisiteExterieur,
  isContratTypeVisiteSurPieces,
  contratTypePrestationColor,
  contratTypePrestationColorDefault,
  contratTypePrestationRapportExpertise,
  contratTypePrestationCertificatExpertise,
  isPrestationsSurfaceAnnexeTypeAutre,
  isEvaluationMethodeRetenueComparaison,
  isEvaluationMethodeRetenueComparaisonEtRendement,
  isEvaluationMethodeRetenueRendement,
  isPrestationsDependanceTypeAutre,
  isSourceDesSurfacesActeNotarie,
  isContratConditionOccupationOccupe,
  isFiscaleSourceTaxeFonciereDocumentConsulte,
  isContratTypePrestationNotCertificatExpertise,
  hasValeurApresTravaux,
  hasValeurLocative,
  hasOptionFaciliteDeNegociationESFI,
  isRapportVersionGreaterOrEqualsTo,
  isRapportVersionLowerOrEqualsTo,
  areBusinessEntryRelationsValid,
  isBusinessEntryDPEValid,
};
