















































































import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator';

import Radios from '@/shared/components/radios.vue';
import DatePicker from '@/shared/components/date-picker.vue';
import NumericNullable from '@/shared/components/numeric-nullable.vue';
import { isElementComparaisonTransaction, isElementComparaisonValorisation } from '@/helpers/rapport-expertise.helper';
import RapportExpertiseFileUploader from '@/shared/components/rapport-expertise/rapport-expertise-file-uploader.vue';
import { EtudeMarcheElementComparaisonDto } from '@/shared/dtos/etude-marche-element-comparaison.dto';
// eslint-disable-next-line no-unused-vars
import ExtendableList from '@/shared/components/extendable-list.vue';

@Component({
  name: 'ElementComparaison',
  components: { Radios, DatePicker, NumericNullable, RapportExpertiseFileUploader },
})
export default class ElementComparaison extends Vue {
  @Prop() public value: EtudeMarcheElementComparaisonDto;
  @Prop() public typeSource: any;
  @Prop() public index: number;
  @Prop() public estVente: boolean;
  @Prop() public validation: any;
  @Prop() public piecesJointes: any;

  @Inject('addPieceJointe') public addPieceJointe: any;

  public libelle: any = null;
  public libelleCalcule: any = null;
  public typeSourceSelectionne: any = null;
  public placeholderDate: any = null;

  //#region LIFE CYCLES
  beforeMount() {
    this.$nextTick(function () {
      this.init();
    });
  }
  //#endregion

  //#region COMPUTED

  get isTransaction() {
    return isElementComparaisonTransaction(this.value);
  }

  get isValorisation() {
    return isElementComparaisonValorisation(this.value);
  }

  //#endregion

  //#region WATCH
  @Watch('value.surface')
  public surfaceChanged(val: any): void {
    if (val != null && val !== 0 && this.value.prixOuLoyer != null && this.value.prixOuLoyer !== 0) {
      this.value.prixOuLoyerCalcule = Math.round(this.value.prixOuLoyer / val);
    } else {
      this.value.prixOuLoyerCalcule = null;
    }
  }

  @Watch('value.prixOuLoyer')
  public prixOuLoyerChanged(val: any): void {
    if (val != null && val !== 0 && this.value.surface != null && this.value.surface !== 0) {
      this.value.prixOuLoyerCalcule = Math.round(val / this.value.surface);
    } else {
      this.value.prixOuLoyerCalcule = null;
    }
  }

  @Watch('value.prixOuLoyerCalcule')
  public prixOuLoyerCalculeChanged(val: any): void {
    if (isNaN(val)) {
      this.value.prixOuLoyerCalcule = 0;
    }
  }

  @Watch('isTransaction')
  public isTransactionChanged(val: boolean): void {
    if (val !== true) {
      this.value.dateTransaction = null;
    } else {
      this.placeholderDate = 'Date de la transaction';
    }
  }

  @Watch('isValorisation')
  public isValorisationChanged(val: boolean): void {
    if (val !== true) {
      this.value.dateTransaction = null;
    } else {
      this.placeholderDate = 'Date de la valorisation';
    }
  }

  @Watch('piecesJointes.comparable')
  public comparablePhotoChanged(newValue: any) {
    if (!newValue.some((pj: any) => pj.id == this.value.photoId)) this.value.photoId = null; // gère le cas où on supprime la photo uniquement
  }

  //#endregion

  //#region EVENTS
  public onUploadedFileId(id: number) {
    if (id != null && id !== 0) this.value.photoId = id;
  }

  //#endregion

  //#region FUNCTIONS
  public async init(): Promise<void> {
    this.value.vente = this.estVente;

    if (this.estVente === true) {
      this.libelle = 'Prix';
      this.libelleCalcule = 'Prix m² calculé';
      this.typeSourceSelectionne = this.typeSource.filter((obj: any) => {
        return obj.id !== 2016003;
      });
    } else {
      this.libelle = 'Loyer mensuel';
      this.libelleCalcule = 'Loyer mensuel calculé / m²';
      this.typeSourceSelectionne = this.typeSource.filter((obj: any) => {
        return obj.id !== 2016002;
      });
    }
  }

  public deleteElementComparaison() {
    (this.$parent as ExtendableList).deleteItem(this.index);
  }
  //#endregion
}
