/* eslint-disable no-alert */

import { Component, Mixins, Provide, Vue, Watch } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import { ComponentSection } from '@/shared/models/component-section';
import store from '@/shared/store';
import roles from '@/shared/constants/roles.const';
import * as _ from 'lodash';
import { BModal } from 'bootstrap-vue';
import { EtapeMissionEnum } from '@/shared/enums/etape-mission.enum';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { Guid } from 'guid-typescript';
import { TypePrestationExpertisePieceJointeEnum } from '@/shared/enums/type-prestation-expertise-piece-jointe.enum';
import { TypePrestationExpertiseEnum } from '@/shared/enums/type-prestation-expertise.enum';
import { RapportExpertiseDto } from '@/shared/dtos/rapport-expertise.dto';
import { SubjectMessageTypeEnum } from '@/shared/enums/subject-message-type.enum';
import { MergeDto } from '@/shared/dtos/merge.dto';
import { PrestationExpertiseProprietesDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-proprietes.dto';
import { PrestationExpertiseVersionHistoryDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-version-history.dto';
import moment from 'moment';
import { TypePieceJointeRapportEnum } from '@/shared/enums/type-piece-jointe-rapport.enum';
import { isContratTypePrestationNotCertificatExpertise } from '@/helpers/rapport-expertise.helper';

Component.registerHooks(['beforeRouteLeave']);
@Component({})
// Cannot create an abstract class with @Component decorator
// Cannot create constructor with arguments  with @Component decorator
// Have to use throw not implemented to emulate abstraction
export default class PrestationExpertise extends Mixins(DeviceMixin) {
  private _saveInterval: any;
  private _modalSuppressionDefaults: any = {
    content: 'Êtes-vous sûr de vouloir supprimer cet élément ?',
    // il faut utiliser les mêmes noms que les propriétés de b-modal pour les binder automatiquement
    visible: false,
    title: 'Supprimer cet élément',
    'ok-title': 'Oui',
    'ok-variant': 'primary',
    'cancel-title': 'Non',
    'cancel-variant': 'light',
  };
  private _hasErrorOccuredWhenSaving = false;

  protected _hasMissionChanged: boolean;
  protected _hasPrestationExpertiseChanged: boolean;
  protected _needToByPasseHasPrestationExpertiseChanged: boolean;
  protected _needToBeSaved: boolean = true;

  public mission: MissionDto = null;
  public lastPrestationExpertiseVersionHistory: PrestationExpertiseVersionHistoryDto = null;
  public businessEntries: any = null;
  public componentsSections: ComponentSection[] = [];
  public currentComponentSection: ComponentSection = null;
  public validation: any = null;
  public hasAccess: boolean = true;

  public isSaving: boolean = false;
  public isExporting: boolean = false;
  public isDepositing: boolean = false;

  public modalSuppression: any = {};

  @Provide('addPieceJointe') addPieceJointeProvide: any = this.addPieceJointe;
  @Provide('deletePieceJointeByIdAndType') deletePieceJointeByIdAndTypeProvide: any = this.deletePieceJointeByIdAndType;
  @Provide('renamePieceJointe') renamePieceJointeProvide: any = this.renamePieceJointe;
  @Provide('updateOrdrePiecesJointes') updateOrdrePiecesJointesProvide: any = this.updateOrdrePiecesJointes;
  @Provide('confirmDelete') confirmDeleteProvide: any = this.confirmDelete;
  @Provide('updateGeolocalisation') updateGeolocalisationProvide: any = this.updateGeolocalisation;

  //#region LIFE CYCLES
  public beforeMount(): void {
    this.mission = this.$route.meta.mission;
    this.businessEntries = Vue.prototype.$localStorageService.getBusinessEntries();
    this._hasMissionChanged = false;
    this._hasPrestationExpertiseChanged = false;
    this._needToByPasseHasPrestationExpertiseChanged = false;
    this.validation = this.$v;

    // Remove old missions from the cache only for non master SousTraitant
    if (store.user.roles.includes(roles.SousTraitant) && !store.comeFromLoginPage) {
      Vue.prototype.$missionStoreService.deleteLowerEntriesByPropertyAndValue('dateVisite', moment().subtract(30, 'days'), true);
    }

    if (
      this.mission.expertise.suiviMission.dateDeRestitution &&
      moment(this.mission.expertise.suiviMission.dateDeRestitution) < moment().add(-30, 'days') &&
      store.user.roles.includes(roles.SousTraitant) &&
      !store.comeFromLoginPage
    ) {
      this.hasAccess = false;
      Vue.prototype.$notificationService.success(`Vous n'avez plus accès à ce rapport`);
    } else {
      if (this.isBrowser) {
        // Check for PrestationExpertise updates and conflicts only for the Desktop
        this.getLastHistory(true);
      }

      this._saveInterval = setInterval(async () => {
        if (!this.isReadOnly) {
          await this.saveInStorage();
        }
      }, 3000);
    }
  }

  public mounted(): void {
    if (this.hasAccess) {
      this.filterComponentSections();

      setTimeout(() => {
        this.$watch(
          'mission.dateVisite',
          async () => {
            if (!this.mission.dateVisite) return;

            const logMessage = `Mise à jour de la date de visite de la prestation d'expertise attachée à la mission: ${this.mission.reference}`;

            await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertise');

            Vue.prototype.$missionApiService
              .updateDateVisite(this.mission.id, this.mission.dateVisite)
              .then(async () => {
                Vue.prototype.$notificationService.success(`La date de visite a bien été mise à jour`);
                await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertise');
              })
              .catch(async (error: any) => {
                Vue.prototype.$notificationService.error(`Erreur lors de la mise à jour de la date de visite`);
                await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertise');
              });
            this._hasMissionChanged = true;
          },
          { deep: true }
        );

        this.$watch(
          'mission.prestationExpertise',
          () => {
            this._hasPrestationExpertiseChanged = true;
          },
          { deep: true }
        );
      }, 100);
    }
  }

  public created() {
    window.addEventListener('beforeunload', this.handleUnload);
  }

  public async beforeRouteLeave(to: any, from: any, next: any): Promise<any> {
    if (this._needToBeSaved && this.isBrowser) {
      var result = confirm('Les modifications que vous avez apportées ne seront peut-être pas enregistrées. Voulez-vous continuer ?');
      if (!result) {
        return next(false);
      } else {
        return next();
      }
    } else {
      return next();
    }
  }

  public async beforeDestroy(): Promise<void> {
    window.removeEventListener('beforeunload', this.handleUnload);

    await this.saveInStorage();
    this._hasMissionChanged = false;
    this._hasPrestationExpertiseChanged = false;
    clearInterval(this._saveInterval);
  }

  //#endregion

  //#region COMPUTED
  get componentsSectionsForMobile(): ComponentSection[] {
    const componentsSectionsDepth1 = this.componentsSections
      .filter((componentSection: ComponentSection) => !componentSection.isOnlyForDesktop)
      .map((componentSection: ComponentSection) => componentSection.children);
    return componentsSectionsDepth1
      .reduce(
        (componentsSections1: ComponentSection[], componentsSections2: ComponentSection[]) =>
          componentsSections1.concat(componentsSections2),
        []
      )
      .filter((componentSection: ComponentSection) => !componentSection.isOnlyForDesktop);
  }

  get isReadOnly(): boolean {
    if (
      this.mission.prestationExpertise.proprietes &&
      (this.mission.prestationExpertise.proprietes as PrestationExpertiseProprietesDto).estEditionForcee &&
      (store.user.roles.includes(roles.AdminBPCEEI) || store.user.roles.includes(roles.Expert))
    ) {
      return false;
    }

    if (
      !this.$can('Save', 'Rapport') ||
      (this.mission.etape !== EtapeMissionEnum.AttribueeALExpert && this.mission.etape !== EtapeMissionEnum.ValeursARevoir) ||
      this.mission.expertise.suiviMission.estDepose
    ) {
      return true;
    }

    return false;
  }

  get isLoading(): boolean {
    return store.isLoading || this.isSaving || this.isExporting || this.isDepositing;
  }

  get color(): string {
    throw new Error('Not implemented');
  }

  get darkenColor(): string {
    throw new Error('Not implemented');
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  public onEmitComponentSectionChanged(componentSection: ComponentSection): void {
    this.currentComponentSection = componentSection;
  }

  public async onEmitSaveButtonClicked(): Promise<void> {
    await this.save();
  }

  public async onEmitExportButtonClicked(): Promise<void> {
    await this.export();
  }

  public onEmitDeposerButtonClicked(): void {
    this.deposer();
  }

  //#endregion

  //#region FUNCTIONS
  private handleUnload(event: any): void {
    if (this._needToBeSaved && this.isBrowser) {
      event.preventDefault();
      event.returnValue = '';
    }
  }

  public filterComponentSections(): void {
    this.componentsSections = this.componentsSections.filter(
      (componentSection: ComponentSection) =>
        componentSection.isForCustomCondition === undefined || componentSection.isForCustomCondition === true
    );

    // Permission management
    this.componentsSections = this.filterWithPermissions(this.componentsSections);

    this.componentsSections.forEach((componentSection: ComponentSection) => {
      componentSection.children = this.filterWithPermissions(
        componentSection.children.filter((x) => x.isForCustomCondition === undefined || x.isForCustomCondition === true)
      );
    });

    this.currentComponentSection = this.isBrowser
      ? this.componentsSections[0]
      : this.componentsSectionsForMobile.find((componentSection: ComponentSection) => componentSection.isActive) ??
        this.componentsSectionsForMobile[0];
  }

  private filterWithPermissions(componentsSections: ComponentSection[]): ComponentSection[] {
    return componentsSections.filter((componentSection: ComponentSection) => {
      let permissionsParts = undefined;

      if (componentSection.permission) {
        permissionsParts = componentSection.permission.split('|');
      }

      return permissionsParts === undefined || this.$can(permissionsParts[0], permissionsParts[1]);
    });
  }

  protected cleanBeforeSaveInStorage(missionToClean: MissionDto): void {
    throw new Error('Not Implemented');
  }

  protected async cleanBeforeSave(): Promise<void> {
    throw new Error('Not Implemented');
  }

  protected touchDatas(): boolean {
    throw new Error('Not Implemented');
  }

  private async getLastHistory(isPopupDisplayed: boolean): Promise<void> {
    await Vue.prototype.$prestationExpertiseVersionHistoryApiService
      .getWithRoute(`status/${this.mission.prestationExpertise.id}`)
      .then(async (prestationExpertiseVersionHistory: PrestationExpertiseVersionHistoryDto) => {
        if (isPopupDisplayed)
          Vue.prototype.$notificationService.success(`Version récupérée : ${prestationExpertiseVersionHistory.strLastVersion}`);

        this.lastPrestationExpertiseVersionHistory = prestationExpertiseVersionHistory;
      });
  }

  protected async saveInStorage(force = false): Promise<void> {
    if (((this._hasMissionChanged || this._hasPrestationExpertiseChanged) && !this.isReadOnly) || force) {
      const dateModification = new Date();

      if (this._hasMissionChanged) this.mission.dateModification = dateModification;
      if (this._hasPrestationExpertiseChanged && !this._needToByPasseHasPrestationExpertiseChanged) {
        this.mission.prestationExpertise.dateModificationUtc = dateModification;
        this._needToBeSaved = true;
      }

      this.cleanBeforeSaveInStorage(this.mission);

      await Vue.prototype.$missionStoreService.addOrUpdate(this.mission);

      this._hasMissionChanged = false;
      this._hasPrestationExpertiseChanged = false;
      this._needToByPasseHasPrestationExpertiseChanged = false;
    }
  }

  protected async save(): Promise<void> {
    if (this.isReadOnly) return;

    this.isSaving = true;
    this._hasErrorOccuredWhenSaving = false;

    this.$bvToast.show('save-toast');

    await this.cleanBeforeSave();

    await Vue.prototype.$synchronizationService
      .updateMission(this.mission)
      .then((result: boolean) => {
        this._hasErrorOccuredWhenSaving = result;
        this._hasMissionChanged = false;
        this._hasPrestationExpertiseChanged = false;
        this._needToByPasseHasPrestationExpertiseChanged = false;
        this._needToBeSaved = false;

        this.getLastHistory(false);
      })
      .catch(async (error: any) => {
        this._hasErrorOccuredWhenSaving = true;
      })
      .finally(() => {
        this.isSaving = false;
        this.$bvToast.hide('save-toast');
      });

    if (!this._hasErrorOccuredWhenSaving) {
      Vue.prototype.$notificationService.success(`Données synchronisées avec succès.`);
    } else {
      Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la synchronisation des données.`);
    }
  }

  protected async export(): Promise<void> {
    if (!this.isReadOnly) {
      await this.save().then(async () => {
        if (this._hasErrorOccuredWhenSaving) {
          Vue.prototype.$notificationService.warn(`L'enregistrement à échoué, le rapport PDF risque d'être incomplet.`);
        }
        await this.processExport();
      });
    } else {
      await this.processExport();
    }
  }

  private async processExport(): Promise<void> {
    this.isExporting = true;

    this.$bvToast.show('export-toast');

    const logMessage = `Téléchargement de la prestation d'expertise attachée à la mission: ${this.mission.reference}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertise');

    await this.axios
      .get(`Export/PrestationExpertise`, {
        baseURL: Vue.prototype.$config.baseUrlApi,
        responseType: 'blob',
        params: { missionId: this.mission.id },
      })
      .then(async (response: any) => {
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertise');

        let blob = new Blob([response.data], { type: 'application/pdf' });

        let filename;
        try {
          let disposition = response.headers['content-disposition'];
          filename = decodeURI(disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]);
        } catch {
          filename = `${this.mission.typePrestationExpertiseString}_${this.mission.reference.replace('/', '_')}.pdf`;
        }

        //msSaveOrOpenBlob for IE
        const navigator: any = window.navigator;
        if (navigator && navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, filename);
        } else {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link); // fix for Firefox
          link.click();
          link.remove();
        }
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors du téléchargement de la prestation d'expertise.`);
        await Vue.prototype.$logStoreService.info(`${logMessage} - ERROR`, error, 'PrestationExpertise');
      })
      .finally(() => {
        this.isExporting = false;
        this.$bvToast.hide('export-toast');
      });
  }

  protected async deposer(): Promise<void> {
    if (this.isReadOnly) return;

    if (this.touchDatas()) {
      this.$nextTick(() => {
        /* eslint-disable no-console */
        console.log(`Dépôt de la mission ${this.mission.reference} échoué`, this.validation);

        Vue.prototype.$notificationService.warn(`Veuillez compléter la prestation d'expertise avant de la déposer.`);

        const invalidElements = document.getElementsByClassName('invalid');
        if (invalidElements && invalidElements.length) {
          var invalidElement = invalidElements[0];
          invalidElement.scrollIntoView();
        }
      });
      return;
    }

    await this.save()
      .then(async () => {
        this.isDepositing = true;

        this.$bvToast.show('depot-toast');

        const logMessage = `Dépot de la prestation d'expertise attachée à la mission: ${this.mission.reference}`;

        await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertise');

        await Vue.prototype.$expertiseApiService
          .deposer(this.mission.expertise.id, this.mission.id, this.mission.typePrestationExpertise)
          .then(async () => {
            this.mission.expertise.suiviMission.estDepose = true;
            await this.saveInStorage(true);

            await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertise');

            Vue.prototype.$notificationService.success(`La prestation d'expertise a bien été déposée.`);

            if (
              [TypePrestationExpertiseEnum.CertificatExpertise, TypePrestationExpertiseEnum.RapportExpertise].includes(
                this.mission.typePrestationExpertise
              )
            ) {
              this._needToByPasseHasPrestationExpertiseChanged = true;

              (this.mission.prestationExpertise as RapportExpertiseDto).proprietes.estEditionForcee = false;
            }
          })
          .catch(async (error: any) => {
            Vue.prototype.$notificationService.error(`Une erreur est survenue lors du dépôt de la prestation d'expertise.`);
            await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertise');
          })
          .finally(() => {
            this.isDepositing = false;
          });
      })
      .finally(() => {
        this.isDepositing = false;
        this.$bvToast.hide('depot-toast');
      });
  }

  public async addPieceJointe(type: string, base64Content: string, nom: string): Promise<string> {
    const pieceJointe: PrestationExpertisePieceJointeDto = {
      id: Guid.create().toString(),
      type: type as unknown as TypePrestationExpertisePieceJointeEnum,
      content: base64Content,
      nom: nom,
      ordre: this.mission.prestationExpertise.piecesJointes[type] ? this.mission.prestationExpertise.piecesJointes[type].length + 1 : 1,
      prestationExpertiseId: this.mission.prestationExpertise.id.toString(),
      dateModificationUtc: new Date(),
      isNew: true,
      isMain: false,
    };

    if (type == TypePieceJointeRapportEnum[TypePieceJointeRapportEnum.PlanCadastre]) {
      this.mission.prestationExpertise.piecesJointes[type] = [];
    }

    this.mission.prestationExpertise.piecesJointes[type].push(pieceJointe);

    return pieceJointe.id;
  }

  public async deletePieceJointeByIdAndType(id: string, type: string): Promise<void> {
    const pieceJointeToDelete = this.mission.prestationExpertise.piecesJointes[type].find(
      (pieceJointe: PrestationExpertisePieceJointeDto) => pieceJointe.id === id
    );

    if (pieceJointeToDelete.isNew) {
      this.mission.prestationExpertise.piecesJointes[type] = this.mission.prestationExpertise.piecesJointes[type].filter(
        (pieceJointe: PrestationExpertisePieceJointeDto) => pieceJointe.id !== id
      );
    } else {
      pieceJointeToDelete.isDeleted = true;
    }

    // Re affect the table to trigger change
    this.mission.prestationExpertise.piecesJointes[type] = [...this.mission.prestationExpertise.piecesJointes[type]];
  }

  public async renamePieceJointe(pieceJointeId: string, type: string, nom: string): Promise<void> {
    const pieceJointeToRename = this.mission.prestationExpertise.piecesJointes[type].find(
      (pieceJointe: PrestationExpertisePieceJointeDto) => pieceJointe.id === pieceJointeId
    );
    const index = this.mission.prestationExpertise.piecesJointes[type].indexOf(pieceJointeToRename);

    pieceJointeToRename.nom = nom;
    pieceJointeToRename.dateModificationUtc = new Date();
    this.mission.prestationExpertise.piecesJointes[type][index] = pieceJointeToRename;

    await this.saveInStorage();
  }

  public async updateOrdrePiecesJointes(piecesJointes: PrestationExpertisePieceJointeDto[], type: string): Promise<any> {
    const now = new Date();
    piecesJointes.forEach((pieceJointe: PrestationExpertisePieceJointeDto) => (pieceJointe.dateModificationUtc = now));

    this.mission.prestationExpertise.piecesJointes[type] = piecesJointes;

    await this.saveInStorage();
  }

  public confirmDelete(what: any, params: any): any {
    return new Promise<void>((resolve: any) => {
      this.modalSuppression = Object.assign(
        {},
        this._modalSuppressionDefaults,
        {
          visible: true,
          title: 'Suppression',
          content: `Êtes-vous sûr de vouloir supprimer ${what} ?`,
        },
        params
      );
      (this.$refs.modalSuppression as BModal).$once('ok', () => resolve());
    });
  }

  public async updateGeolocalisation(): Promise<void> {
    if (this.isReadOnly) return;

    store.isLoading = true;

    const logMessage = `Enregistrement de la géolocalisation pour la mission : ${this.mission.reference}`;

    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertise');

    Vue.prototype.$missionApiService
      .updateGeolocalisation(this.mission)
      .then(async () => {
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertise');
        Vue.prototype.$notificationService.success(`La géolocalisation a été enregistrée avec succès.`);

        Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.INIT_DONNEES_ESTIMATION_IMMOBILIERES);
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de l'enregistrement de géolocalisation.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertise');
      })
      .finally(() => {
        store.isLoading = false;
      });
  }

  public async initMergeDatas(): Promise<void> {
    if (!navigator.onLine) return;

    const logMessage = `Merge des données pour la mission : ${this.mission.reference}`;
    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'PrestationExpertise');

    const clonedMission = _.cloneDeep(this.mission);
    clonedMission.prestationExpertise = null;

    const isOldGenPrestationExpertise = [
      TypePrestationExpertiseEnum.CertificatExpertise,
      TypePrestationExpertiseEnum.RapportExpertise,
    ].includes(clonedMission.typePrestationExpertise);
    if (isOldGenPrestationExpertise) {
      const rapportExpertise = this.mission.prestationExpertise as RapportExpertiseDto;
      // Valeur Serexim
      const isRapportExpertise: boolean = isContratTypePrestationNotCertificatExpertise(rapportExpertise.contratTypePrestationId);

      // Valeur Omere
      const isTypeRapportExpertiseRapportExpertise: boolean =
        this.mission.typePrestationExpertise === TypePrestationExpertiseEnum.RapportExpertise;

      // Si les 2 valeurs ne sont pas identiques, alors provoquer le changement de type de rapport
      if (isRapportExpertise && !isTypeRapportExpertiseRapportExpertise) {
        clonedMission.typePrestationExpertise = TypePrestationExpertiseEnum.RapportExpertise;
      } else {
        if (!isRapportExpertise && isTypeRapportExpertiseRapportExpertise) {
          clonedMission.typePrestationExpertise = TypePrestationExpertiseEnum.CertificatExpertise;
        }
      }
    }

    Vue.prototype.$missionApiService
      .getMerge(this.mission.id, clonedMission)
      .then(async (merges: MergeDto[]) => {
        const mergesDto = merges.filter((x) => x.isEquals === false);
        if (mergesDto.length > 0) {
          Vue.prototype.$subjectMessageService.next(SubjectMessageTypeEnum.SHOW_MERGE_MISSION_MODAL, mergesDto);
        }
        await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'PrestationExpertise');
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(
          `Une erreur est survenue lors de l'enregistrement de la récupération des données à fusionner.`
        );
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'PrestationExpertise');
      });
  }

  public async changeTypePrestationExpertise(mergeTypePrestationExpertise: MergeDto, title: string): Promise<void> {
    store.isLoading = true;
    const logMessage = `Changement de type de prestation d'expertise attachée à la mission ${this.mission.reference}.`;
    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, title);

    return await Vue.prototype.$missionApiService
      .changeTypePrestationExpertise(
        this.mission.id,
        Number(mergeTypePrestationExpertise.oldValue),
        Number(mergeTypePrestationExpertise.newValue)
      )
      .then(async () => {
        Vue.prototype.$missionApiService.get(this.mission.id).then(async (missionResponse: MissionDto) => {
          await Vue.prototype.$missionStoreService.addOrUpdate(missionResponse);
          this.$router
            .push({
              path: `/missions/${this.mission.id}/${TypePrestationExpertiseEnum[mergeTypePrestationExpertise.newValue]}`,
              replace: true,
            })
            .then(async () => {
              Vue.prototype.$localStorageService.removeLastHistory();
              await Vue.prototype.$logStoreService.info(`${logMessage} - END`, title);
              store.isLoading = false;
            })
            .catch(() => {
              window.location.reload();
              store.isLoading = false;
            });
        });
      })
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors du changement de type de prestation.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, title);
        store.isLoading = false;
      });
  }

  //#endregion
}
