import { DonneesEstimationImmobiliereDto } from '@/shared/dtos/donnees-estimation-immobiliere.dto';
import { EtudeMarcheElementComparaisonDto } from '@/shared/dtos/etude-marche-element-comparaison.dto';
import axios from 'axios';
import { BaseDtoApiService } from './base-dto-api.service';

export class DonneesEstimationImmobiliereApiService extends BaseDtoApiService<DonneesEstimationImmobiliereDto> {
  constructor() {
    super('donneesEstimationImmobiliere');
  }

  public authenticate(): Promise<string> {
    return axios.get(`${this._endpoint}/authenticate`).then((response: any) => {
      return response.data.result as string;
    });
  }

  public getComparables(dossierId: string): Promise<EtudeMarcheElementComparaisonDto[]> {
    return axios.get(`${this._endpoint}/${dossierId}/comparables`).then((response: any) => {
      return response.data.result as EtudeMarcheElementComparaisonDto[];
    });
  }

  public removeComparables(dossierId: string): Promise<void> {
    return axios.delete(`${this._endpoint}/${dossierId}/comparables`);
  }
}
