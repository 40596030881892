


















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Suggestions from 'v-suggestions';
import 'v-suggestions/dist/v-suggestions.css';
import urljoin from 'url-join';
import { geocode, addressInputChange } from '../utilities/google-maps.utility';

@Component({
  name: 'google-map',
  components: {
    Suggestions,
  },
})
export default class GoogleMap extends Vue {
  @Prop() public id: string;
  @Prop() public address: string;
  @Prop() public situationGeneraleSrc: string;
  @Prop() public situationParticuliereSrc: string;
  @Prop() public vueAerienneSrc: string;
  @Prop() public latitude: number;
  @Prop() public longitude: number;
  @Prop({ default: true }) public isCaptureDisplayed: boolean;
  @Prop({ required: true }) public showCaptureAerienne: boolean;

  public mapId: string;
  public autoCompleteId: string;
  public suggestedAddress: string = null;
  public autoCompleteService: any = null;
  public apiKey: string = Vue.prototype.$config.googleMapApiKey;
  public map: any = null;
  public bounds: any = null;
  public marker: any = null;
  public sessionToken: any = null;
  public geocoderService: any = null;
  public options: any = {
    placeholder: 'Géolocaliser une adresse',
    inputClass: 'v-suggestions-input col mb-3 search',
  };

  //#region LIFE CYCLES
  public mounted(): void {
    this.initComponent();
    this.updateCoordinates();
  }

  public beforeUpdate(): void {
    this.updateCoordinates();
  }

  public created(): void {
    this.mapId = this.id + '-map';
    this.autoCompleteId = this.id + '-autoComplete';
    this.suggestedAddress = this.address;
  }
  //#endregion

  //#region COMPUTED
  get captureGeneraleSrc() {
    if (this.situationGeneraleSrc != null && !this.situationGeneraleSrc.startsWith('http')) {
      return urljoin(Vue.prototype.$config.baseUrlApi, this.situationGeneraleSrc);
    }

    return this.situationGeneraleSrc;
  }

  get captureParticuliereSrc() {
    if (this.situationParticuliereSrc != null && !this.situationParticuliereSrc.startsWith('http')) {
      return urljoin(Vue.prototype.$config.baseUrlApi, this.situationParticuliereSrc);
    }

    return this.situationParticuliereSrc;
  }

  get captureVueAerienneSrc() {
    if (this.vueAerienneSrc != null && !this.vueAerienneSrc.startsWith('http')) {
      return urljoin(Vue.prototype.$config.baseUrlApi, this.vueAerienneSrc);
    }

    return this.vueAerienneSrc;
  }

  //#endregion

  //#region WATCH
  @Watch('address')
  public addressValueChanged(value: any): void {
    this.suggestedAddress = value;
  }

  //#endregion

  //#region EVENTS
  public async onAddressInputChange(value: string): Promise<string[]> {
    return await addressInputChange(value, this.autoCompleteService, this.sessionToken);
  }

  public addressSelected(value: any): any {
    this.geocode({ address: value }).then(this.setSuggestedAddress).then(this.addMarker).then(this.centerMaker).then(this.getCoordinates);
  }

  public markerDragged(): void {
    this.geocode({ location: this.marker.getPosition() })
      .then(this.setSuggestedAddress)
      .then(this.addMarker)
      .then(this.centerMaker)
      .then(this.getCoordinates);
  }
  //#endregion

  //#region FUNCTIONS
  private updateCoordinates(): void {
    if (this.longitude != null && this.latitude != null) {
      this.addMarker({
        geometry: { location: { lat: this.latitude, lng: this.longitude } },
      }).then(this.centerMaker);
    } else {
      this.geocode({ address: this.suggestedAddress }).then(this.addMarker).then(this.centerMaker).then(this.getCoordinates);
    }
  }

  private mapPlaceGoogleMapsToAdresse(place: any): string {
    const adresseGoogleMapsForm: any = {
      street_number: 'short_name',
      route: 'long_name',
      postal_code: 'short_name',
      locality: 'long_name',
      country: 'long_name',
    };

    const result: any = [];
    for (const address of place.address_components) {
      const addressType: any = address.types[0];
      if (adresseGoogleMapsForm[addressType]) {
        result[addressType] = address[adresseGoogleMapsForm[addressType]];
      }
    }

    if (result.postal_code === undefined) {
      Vue.prototype.$notificationService.error(`Le code postal n'a pas été retourné par Google Maps, veuillez réessayer.`);
      return null;
    }

    let addressString = '';
    if (result.street_number) {
      addressString += ` ${result.street_number}`;
    }

    if (result.route) {
      addressString += ` ${result.route}`;
    }

    if (result.postal_code) {
      addressString += ` ${result.postal_code}`;
    }

    if (result.locality) {
      addressString += ` ${result.locality}`;
    }

    if (result.country) {
      addressString += ` ${result.country}`;
    }

    return addressString.substring(1);
  }

  public initComponent(): void {
    this.map = new Vue.prototype.$googleMapsApi.Map(document.getElementById(this.mapId), {
      center: new Vue.prototype.$googleMapsApi.LatLng(40.725118, -73.997699),
      zoom: 14,
    });
    this.geocoderService = new Vue.prototype.$googleMapsApi.Geocoder();
    this.sessionToken = new Vue.prototype.$googleMapsApi.places.AutocompleteSessionToken();
    this.autoCompleteService = new Vue.prototype.$googleMapsApi.places.AutocompleteService();
  }

  public geocode(parameters: any): any {
    return geocode(this.geocoderService, parameters);
  }

  public setSuggestedAddress(place: any): any {
    const value = this.mapPlaceGoogleMapsToAdresse(place);
    if (value) {
      this.suggestedAddress = value;
      return place;
    }
  }

  public addMarker(geolocalisation: any): any {
    return new Promise<void>((resolve: any) => {
      this.deleteMarker();
      const location = geolocalisation.geometry.location;
      this.marker = new Vue.prototype.$googleMapsApi.Marker({
        map: this.map,
        position: location,
        draggable: true,
      });
      this.marker.addListener('dragend', this.markerDragged);
      resolve();
    });
  }

  public deleteMarker(): void {
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }
  }

  public centerMaker(): void {
    this.map.setCenter(this.marker.getPosition());
  }

  public getCoordinates(): void {
    this.$emit('adresseChanged', this.suggestedAddress);
    this.$emit('coordinatesChanged', this.marker.position.lat(), this.marker.position.lng());
  }

  public onCaptureSituationGeneraleClick(): void {
    this.$emit('situationGeneraleCaptured', this.captureSituation());
  }

  public onCaptureSituationParticuliereClick(): void {
    this.$emit('situationParticuliereCaptured', this.captureSituation());
  }

  public onVueSituationAerienneClick(): void {
    this.$emit('situationAerienneCaptured', this.captureSituation());
  }

  public captureSituation(): string {
    const staticMapUrl = 'https://maps.googleapis.com/maps/api/staticmap';
    const mapCenter = this.map.getCenter();
    const markerPosition = this.marker.getPosition();
    const mapType = this.map.getMapTypeId();
    return (
      `${staticMapUrl}` +
      `?center=${mapCenter.lat()},${mapCenter.lng()}` +
      `&zoom=${this.map.getZoom()}` +
      `&size=640x400` +
      `&key=${this.apiKey}` +
      `&markers=${markerPosition.lat()},${markerPosition.lng()}` +
      `&scale=2` +
      `&maptype=${mapType}`
    );
  }
  //#endregion
}
