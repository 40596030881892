/* eslint-disable no-alert */

import Vue from 'vue';
import axios from 'axios';
import abp from 'abp-web-resources';
import { Guid } from 'guid-typescript';
import store from './shared/store';

async function errorResponseHandler(error: any) {
  //Vue.prototype.$loadingProgressionService.stop();

  if (error.response) {
    switch (error.response.status) {
      // Si on a un erreur d'authentification alors qu'on est normalement déconnecté, on force la déconnexion
      case 401:
        Vue.prototype.$authentificationService.logout();
        break;

      // Si on a une 404 on retourne une réponse valide avec un null ou une liste vide
      case 404:
        return Promise.resolve(error.response);
      // Si erreur gérée
      case 900:
        if (!Guid.isGuid(error.response)) {
          Vue.prototype.$notificationService.error(error.response.data, null, true);

          store.isLoading = false;

          return Promise.reject(error.response);
        }
        break;
      case 901:
        if (!Guid.isGuid(error.response)) {
          Vue.prototype.$notificationService.warn(error.response.data);

          store.isLoading = false;

          return Promise.resolve(error.response);
        }
        break;
      default:
        var message = '';
        if (error.response && error.response.data) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          message = error.response.data;
        } else {
          // Something happened in setting up the request that triggered an Error
          message = error.message;
        }

        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de l'appel au serveur.`);

        store.isLoading = false;

        await Vue.prototype.$logStoreService.error(message, error, `HTTP (${error.response.status})`);

        return Promise.reject(error);
    }
  } else {
    store.isLoading = false;
    return Promise.reject(error);
  }
}

axios.interceptors.response.use((response) => response, errorResponseHandler);

axios.interceptors.request.use(
  async (request) => {
    if (
      (request.baseURL == Vue.prototype.$config.baseUrlApiAppService ||
        request.baseURL == Vue.prototype.$config.baseUrl ||
        request.baseURL == Vue.prototype.$config.baseUrlApi) &&
      !request.headers.Authorization
    ) {
      if (abp.auth.getToken()) {
        request.headers.Authorization = 'Bearer ' + abp.auth.getToken();
      }
    }

    // Do not log some requests
    var excludedStrings = ['tokenauth', 'getcurrentlogininformations', 'status'];
    if (
      !excludedStrings.some((excludedString: string) => {
        return request.url.toLowerCase().indexOf(excludedString) !== -1;
      })
    ) {
      await Vue.prototype.$logStoreService.debug(`${request.method.toUpperCase()} - url : ${request.url}`);
    }

    return request;
  },
  (error) => Promise.reject(error)
);
