






















































import DeviceMixin from '@/mixins/device.mixin';
import { AnnexeStatutEnum } from '@/shared/enums/annexe-statut.enum';
import { BusinessEntryEnum } from '@/shared/enums/business-entry.enum';
import { mixins } from 'vue-class-component';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import Radios from '../radios.vue';

@Component({
  name: 'RapportExpertiseDpe',
  components: {
    Radios,
  },
})
export default class RapportExpertiseDpe extends mixins(DeviceMixin) {
  @Prop() public value: number;
  @Prop() public rapportId: number;
  @Prop() public caracteristiquesDPEs: any;
  @Prop() public annexeEnvironnements: any;
  @Prop() public annexesStatuts: any;
  @Prop() public state: any;
  @Prop() public version: any;

  public annexeStatutId?: number = null;

  public listDpe: any = null;

  //#region LIFE CYCLES

  public beforeMount(): void {
    this.initDPE();
  }

  //#endregion

  //#region COMPUTED

  get isObtenu() {
    return this.annexeStatutId == AnnexeStatutEnum.Obtenu;
  }

  get caracteristiqueDpeSelected() {
    return this.caracteristiquesDPEs.find((x: any) => x.id === this.value);
  }

  //#endregion

  //#region WATCH
  @Watch('annexeStatutId')
  public annexeStatutIdChanged(value: number) {
    this.$emit('AnnexeDpeEmit', value);
    if (!this.isObtenu) {
      this.$emit('input', null);
    }
  }

  //#endregion
  //#region EVENTS

  public onSelectDpeClick(dpe: any) {
    this.$emit('input', dpe.id);
  }

  //#endregion
  //#region FUNCTIONS

  private initDPE(): void {
    this.listDpe = [
      {
        id: 2059001,
        label: 'A',
        kWhLabel: '<= 70',
        CO2Label: '<= 6',
      },
      {
        id: 2059002,
        label: 'B',
        kWhLabel: '71 à 110',
        CO2Label: '7 à 11',
      },
      {
        id: 2059003,
        label: 'C',
        kWhLabel: '111 à 180',
        CO2Label: '12 à 30',
      },
      {
        id: 2059004,
        label: 'D',
        kWhLabel: '181 à 250',
        CO2Label: '31 à 50',
      },
      {
        id: 2059005,
        label: 'E',
        kWhLabel: '251 à 330',
        CO2Label: '51 à 70',
      },
      {
        id: 2059006,
        label: 'F',
        kWhLabel: '331 à 420',
        CO2Label: '71 à 100',
      },
      {
        id: 2059007,
        label: 'G',
        kWhLabel: '>= 421',
        CO2Label: '>= 101',
      },
    ];

    const annexeDPE = this.annexeEnvironnements.find((x: any) => x.businessEntrySelectedId === BusinessEntryEnum.DPE);
    if (annexeDPE) this.annexeStatutId = annexeDPE.businessEntryQualificationId;
  }

  public getDPE(id: number): any {
    return this.listDpe.find((x: any) => x.id == id);
  }

  public isRapportVersionGreaterOrEqualsTo(version: number): boolean {
    return this.version && version >= version;
  }

  //#endregion
}
