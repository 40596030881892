





































































import { Component, Inject, Mixins, Prop, Vue } from 'vue-property-decorator';
import DeviceMixin from '@/mixins/device.mixin';
import RapportExpertisePieceJointe from '@/shared/components/rapport-expertise/rapport-expertise-piece-jointe.vue';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import VueGallery from 'vue-gallery';
import { contratTypePrestationColorDefault } from '@/helpers/rapport-expertise.helper';
import { PieceJointeRapportDto } from '@/shared/dtos/piece-jointe-rapport.dto';
import _ from 'lodash';
import imageCompression from 'browser-image-compression';

@Component({
  name: 'RapportExpertiseFileUploader',
  components: {
    RapportExpertisePieceJointe,
    Spinner,
    VueGallery,
  },
})
export default class RapportExpertiseFileUploader extends Mixins(DeviceMixin) {
  @Inject('addPieceJointe') public addPieceJointe: any;
  @Inject('updateOrdrePiecesJointes') public updateOrdrePiecesJointes: any;

  @Prop({ required: true }) public id: string;
  @Prop({ required: true }) public type: string;
  @Prop() public piecesJointes: { [index: string]: PieceJointeRapportDto[] };
  @Prop() public pieceJointeId: number;
  @Prop() public workingById: boolean;
  @Prop({ default: true }) public multiple: boolean;
  @Prop({ default: true }) public showLabel: boolean;
  @Prop({ default: true }) public animate: boolean;
  @Prop({ default: true }) public state: boolean;
  @Prop() public title: string;
  @Prop({ default: false }) public isPhoto: boolean;
  @Prop({ default: null }) public blobValue: string;
  @Prop({ default: null }) public order: number;
  @Prop({ default: false }) public isMain: boolean;

  public isUploading: boolean = false;
  public index: number = null;
  public contratTypePrestationColorDefault: string = contratTypePrestationColorDefault;

  //#region LIFE CYCLES
  async mounted() {
    this.uploadBlobIfNecessary();
  }

  //#endregion

  //#region COMPUTED
  get canUpload(): boolean {
    return this.multiple || (!this.multiple && this.piecesJointes != null && this.filteredPiecesJointes.length == 0);
  }

  get filteredPiecesJointes(): PieceJointeRapportDto[] {
    let filteredPiecesJointes: PieceJointeRapportDto[] = [];
    if (this.workingById) {
      if (this.pieceJointeId != null && this.pieceJointeId != 0)
        filteredPiecesJointes = this.piecesJointes[this.type].filter((p: any) => p.id == this.pieceJointeId);
      else return [];
    } else filteredPiecesJointes = this.piecesJointes[this.type] || [];

    return _.orderBy(
      filteredPiecesJointes.filter((pieceJointe: PieceJointeRapportDto) => !pieceJointe.isDeleted),
      'ordre',
      'asc'
    );
  }

  get images(): string[] {
    return this.filteredPiecesJointes.map((pj: any) => pj.chemin);
  }

  //#endregion

  //#region WATCH

  //#endregion

  //#region EVENTS
  private async processFileUpload(files: any[]): Promise<void> {
    const nbFiles = files.length;
    let nbFileProcessed = 0;

    if (!nbFiles) return;

    // https://www.npmjs.com/package/browser-image-compression
    const options = {
      maxSizeMB: 0.25, // (default: Number.POSITIVE_INFINITY)
      maxWidthOrHeight: 1024, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
      // but, automatically reduce the size to smaller than the maximum Canvas size supported by each browser.
      // Please check the Caveat part for details.

      initialQuality: 1, // optional, initial quality value between 0 and 1 (default: 1)
    };

    for (let file of files) {
      if (!'jpg|jpeg|png'.includes(file.name.toLowerCase().split('.').pop())) {
        this.isUploading = false;
        Vue.prototype.$notificationService.warn(`Le fichier ${file.name} n'est pas une image valide (.jpg,.jpeg,.png)`);
      } else {
        this.isUploading = true;
        // console.log('---------');
        // console.log('originalFile instanceof Blob', file instanceof Blob); // true
        // console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
        file = await imageCompression(file, options);
        // console.log('compressedFile instanceof Blob', file instanceof Blob); // true
        // console.log(`compressedFile size ${file.size / 1024 / 1024} MB`); // smaller than maxSizeMB

        const base64Content = await imageCompression.getDataUrlFromFile(file);

        this.addPieceJointe(this.type, base64Content, file.name, this.order, this.isMain).then((id: number) => {
          if (id) {
            this.pieceJointeId = id;
            this.$emit('uploadedFileId', id);
          }

          nbFileProcessed++;

          if (nbFileProcessed >= nbFiles) {
            this.isUploading = false;
          }
        });
      }
    }
  }

  public async handleFileUpload(e: any): Promise<void> {
    this.processFileUpload(e.target.files);
  }

  //#endregion

  //#region FUNCTIONS
  public async uploadBlobIfNecessary(): Promise<void> {
    if (this.blobValue != null && this.blobValue !== '' && (this.pieceJointeId == 0 || this.pieceJointeId == null)) {
      const filename = `photo_${new Date().getTime()}.jpg`;
      this.isUploading = true;

      // Utilisez ceci pour éviter que l'ID, qui est basé sur un timestamp, soit remplacé par l'ID de l'upload précédent.
      await new Promise((resolve) => setTimeout(resolve, 500));

      let id = await this.addPieceJointe(this.type, this.blobValue, filename, this.order, this.isMain);
      if (id) {
        this.$emit('uploadedFileId', id);
        this.isUploading = false;
      }
    }
  }

  //#endregion
}
