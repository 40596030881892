import { AnnexeTableEnum } from './../shared/enums/annexe-table.enum';
import { PrestationExpertiseAnnexeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-annexe.dto';
import Vue from 'vue';
import { MissionDto } from '@/shared/dtos/mission.dto';
import { TypePrestationExpertiseEnum } from '@/shared/enums/type-prestation-expertise.enum';
import { RapportExpertiseDto } from '@/shared/dtos/rapport-expertise.dto';
import { PieceJointeRapportDto } from '@/shared/dtos/piece-jointe-rapport.dto';
import { PrestationExpertisePieceJointeDto } from '@/shared/dtos/prestation-expertise/prestation-expertise-piece-jointe.dto';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';

export class SynchronizationService {
  constructor() {}

  public async getDonneesTechniques(): Promise<void> {
    const logMessage = 'GET données techniques';
    await Vue.prototype.$logStoreService.info(`${logMessage} - START`, 'SynchronizationService');

    await axios
      .all([
        axios.get('businessentries').then((businessEntriesResponse: any) => {
          return Promise.resolve(businessEntriesResponse);
        }),
        axios.get('prestationsexpertisespointsinspections').then((pointsInspectionsResponse: any) => {
          return Promise.resolve(pointsInspectionsResponse);
        }),
        axios.get('modelesemails').then((modelesemailsResponse: any) => {
          return Promise.resolve(modelesemailsResponse);
        }),
      ])
      .then(
        axios.spread(async (businessEntriesResponse, pointsInspectionsResponse, modelesemailsResponse) => {
          await Vue.prototype.$logStoreService.info('Synchronisation des données techniques depuis le serveur - END', 'Synchro');

          // BusinessEntries
          const businessEntries = businessEntriesResponse.data.result;
          businessEntries.contratCategorieActifs = Vue.prototype.$bienService.getCategorieActifsAsBusinessEntries();
          businessEntries.contratTypeBiens = Vue.prototype.$bienService.getTypesBiensAsBusinessEntries();
          Vue.prototype.$localStorageService.setBusinessEntries(businessEntries);
          await Vue.prototype.$logStoreService.info('Sauvegarde des BusinessEntries dans le LocalStorage', 'Synchro');

          // Points d'inspections
          const pointsInpections = pointsInspectionsResponse.data.result;
          Vue.prototype.$localStorageService.setPointsInspections(pointsInpections);
          await Vue.prototype.$logStoreService.info("Sauvegarde des points d'inspection dans le LocalStorage", 'Synchro');

          // Modèles d'emails
          const modelesemails = modelesemailsResponse.data.result;
          Vue.prototype.$localStorageService.setModelesEmails(modelesemails);
          await Vue.prototype.$logStoreService.info("Sauvegarde des modèles d'email dans le LocalStorage", 'Synchro');

          await Vue.prototype.$logStoreService.info(`${logMessage} - END`, 'SynchronizationService');
        })
      )
      .catch(async (error: any) => {
        Vue.prototype.$notificationService.error(`Une erreur est survenue lors de la récupération des données techniques.`);
        await Vue.prototype.$logStoreService.error(`${logMessage} - ERROR`, error, 'SynchronizationService');
      });
  }

  public async updateMission(missionToPush: MissionDto): Promise<boolean> {
    let hasErrorOccured = false;

    const isOldGenPrestationExpertise = [
      TypePrestationExpertiseEnum.CertificatExpertise,
      TypePrestationExpertiseEnum.RapportExpertise,
    ].includes(missionToPush.typePrestationExpertise);

    // PrestationExpertise.piecesJointes
    if (missionToPush.prestationExpertise) {
      await this.managePiecesJointes(missionToPush, isOldGenPrestationExpertise, hasErrorOccured);
    }

    if (missionToPush.prestationExpertise.annexes) {
      await this.manageAnnexes(missionToPush, missionToPush.prestationExpertise.annexes, hasErrorOccured);
    }

    const logMissionMessage = `UPDATE mission : ${missionToPush.reference}`;

    await Vue.prototype.$logStoreService.info(`${logMissionMessage} - START`, 'SynchronizationService');

    missionToPush.prestationExpertise.dateModificationUtc = new Date();

    const cleanedMission = _.cloneDeep(missionToPush);

    if (isOldGenPrestationExpertise) {
      (cleanedMission.prestationExpertise as RapportExpertiseDto).piecesJointesRapport = null;
    } else {
      cleanedMission.prestationExpertise.piecesJointes = null;
      cleanedMission.prestationExpertise.annexes = null;
    }

    // Send mission.prestationExpertise datas
    await Vue.prototype.$missionApiService
      .update(missionToPush.id, cleanedMission)
      .then(async () => {
        await Vue.prototype.$logStoreService.info(`${logMissionMessage} - END`, 'SynchronizationService');
      })
      .catch(async (error: any) => {
        hasErrorOccured = true;
        await Vue.prototype.$logStoreService.error(`${logMissionMessage} - ERROR`, error, 'SynchronizationService');
      });

    if (!hasErrorOccured) {
      // Update locally
      missionToPush.prestationExpertise.dateDeSauvegardeUtc = new Date();
      await Vue.prototype.$missionStoreService.addOrUpdate(missionToPush);

      return false;
    } else {
      return true;
    }
  }

  private async managePiecesJointes(missionToPush: MissionDto, isOldGenPrestationExpertise: boolean, hasErrorOccured: boolean) {
    if (missionToPush.prestationExpertise) {
      // Do the part between Old Gen and Next Gen PrestationExpertise
      const piecesJointes = isOldGenPrestationExpertise
        ? (missionToPush.prestationExpertise as RapportExpertiseDto).piecesJointesRapport
        : missionToPush.prestationExpertise.piecesJointes;

      const pieceJointeApiService = isOldGenPrestationExpertise
        ? await Vue.prototype.$pieceJointeRapportApiService
        : await Vue.prototype.$prestationExpertisePieceJointeApiService;

      if (piecesJointes) {
        // For each type of piecesJointes
        for (const typePieceJointe in piecesJointes) {
          const piecesJointesToManage = piecesJointes[typePieceJointe];

          // If we have some piecesJointes to process
          if (piecesJointesToManage && piecesJointesToManage.length) {
            // For each piecesJointes, push them and get its id in callback
            for (const pieceJointeToManage of piecesJointesToManage) {
              // Case of new pieceJointe
              if (pieceJointeToManage.isNew) {
                const logPieceJointeMessage = `ADD pièce jointe ${pieceJointeToManage.nom}, mission : ${missionToPush.reference}`;

                await Vue.prototype.$logStoreService.info(`${logPieceJointeMessage} - START`, 'SynchronizationService');

                await pieceJointeApiService
                  .add(pieceJointeToManage)
                  .then(async (addedPieceJointe: PieceJointeRapportDto | PrestationExpertisePieceJointeDto) => {
                    pieceJointeToManage.id = addedPieceJointe.id;
                    pieceJointeToManage.isNew = false;
                    pieceJointeToManage.dateDeSauvegardeUtc = new Date();

                    // Update locally
                    await Vue.prototype.$missionStoreService.addOrUpdate(missionToPush);

                    await Vue.prototype.$logStoreService.info(`${logPieceJointeMessage} - END`, 'SynchronizationService');
                  })
                  .catch(async (error: any) => {
                    hasErrorOccured = true;
                    await Vue.prototype.$logStoreService.info(`${logPieceJointeMessage} - ERROR`, error, 'SynchronizationService');
                  });
                // Case of existing pieceJointe to remove
              } else if (pieceJointeToManage.id && pieceJointeToManage.isDeleted) {
                const logPieceJointeMessage = `DELETE pièce jointe ${pieceJointeToManage.nom}, mission : ${missionToPush.reference}`;

                await Vue.prototype.$logStoreService.info(`${logPieceJointeMessage} - START`, 'SynchronizationService');

                await pieceJointeApiService
                  .delete(pieceJointeToManage.id)
                  .then(async () => {
                    // If delete succeeded on server part, remove it locally
                    piecesJointes[typePieceJointe] = (piecesJointes[typePieceJointe] as any).filter(
                      (pieceJointe: PieceJointeRapportDto | PrestationExpertisePieceJointeDto) => pieceJointe.id !== pieceJointeToManage.id
                    );

                    // Update locally
                    await Vue.prototype.$missionStoreService.addOrUpdate(missionToPush);

                    await Vue.prototype.$logStoreService.info(`${logPieceJointeMessage} - END`, 'SynchronizationService');
                  })
                  .catch(async (error: any) => {
                    hasErrorOccured = true;

                    await Vue.prototype.$logStoreService.error(`${logPieceJointeMessage} - ERROR`, error, 'SynchronizationService');
                  });
                // Case of updated pieceJointe
              } else if (
                (!pieceJointeToManage.dateDeSauvegardeUtc && pieceJointeToManage.dateModificationUtc) ||
                (pieceJointeToManage.dateModificationUtc &&
                  pieceJointeToManage.dateDeSauvegardeUtc &&
                  moment(pieceJointeToManage.dateModificationUtc).valueOf() > moment(pieceJointeToManage.dateDeSauvegardeUtc).valueOf())
              ) {
                const logPieceJointeMessage = `UPDATE pièce jointe ${pieceJointeToManage.nom}, mission : ${missionToPush.reference}`;

                await Vue.prototype.$logStoreService.info(`${logPieceJointeMessage} - START`, 'SynchronizationService');

                // Do not send base64 data to prevent huge request
                const cleanedPieceJointe = _.cloneDeep(pieceJointeToManage);

                if (!isOldGenPrestationExpertise) (cleanedPieceJointe as PrestationExpertisePieceJointeDto).content = null;

                await pieceJointeApiService
                  .update(cleanedPieceJointe.id, cleanedPieceJointe)
                  .then(async () => {
                    pieceJointeToManage.dateDeSauvegardeUtc = new Date();

                    // Update locally
                    await Vue.prototype.$missionStoreService.addOrUpdate(missionToPush);

                    await Vue.prototype.$logStoreService.info(`${logPieceJointeMessage} - END`, 'SynchronizationService');
                  })
                  .catch(async (error: any) => {
                    hasErrorOccured = true;
                    await Vue.prototype.$logStoreService.error(`${logPieceJointeMessage} - ERROR`, error, 'SynchronizationService');
                  })
                  .finally(async () => {});
              }
            }
          }
        }
      }
    }
  }

  private async manageAnnexes(missionToPush: MissionDto, annexes: PrestationExpertiseAnnexeDto[], hasErrorOccured: boolean) {
    for (const annexe in annexes) {
      const annexeToManage = annexes[annexe] as PrestationExpertiseAnnexeDto;
      if (annexeToManage.isNew) {
        const logAnnexeMessage = `ADD annexe ${annexeToManage.nom}, mission : ${missionToPush.reference}`;

        await Vue.prototype.$logStoreService.info(`${logAnnexeMessage} - START`, 'SynchronizationService');

        await Vue.prototype.$annexeApiService
          .addFile(AnnexeTableEnum.Expertise, missionToPush.expertiseId, annexeToManage)
          .then(async (addedPieceJointe: PrestationExpertiseAnnexeDto) => {
            annexeToManage.id = addedPieceJointe.id;
            annexeToManage.isNew = false;
            annexeToManage.dateDeSauvegardeUtc = new Date();

            // Update locally
            await Vue.prototype.$missionStoreService.addOrUpdate(missionToPush);

            await Vue.prototype.$logStoreService.info(`${logAnnexeMessage} - END`, 'SynchronizationService');
          })
          .catch(async (error: any) => {
            hasErrorOccured = true;
            await Vue.prototype.$logStoreService.info(`${logAnnexeMessage} - ERROR`, error, 'SynchronizationService');
          });
      } else if (annexeToManage.id && annexeToManage.isDeleted) {
        const logAnnexeMessage = `DELETE annexe ${annexeToManage.nom}, mission : ${missionToPush.reference}`;
        await Vue.prototype.$logStoreService.info(`${logAnnexeMessage} - START`, 'SynchronizationService');

        await Vue.prototype.$annexeApiService
          .delete(annexeToManage.id)
          .then(async () => {
            missionToPush.prestationExpertise.annexes = missionToPush.prestationExpertise.annexes.filter((x) => x.id !== annexeToManage.id);

            // Update locally
            await Vue.prototype.$missionStoreService.addOrUpdate(missionToPush);

            await Vue.prototype.$logStoreService.info(`${logAnnexeMessage} - END`, 'SynchronizationService');
          })
          .catch(async (error: any) => {
            hasErrorOccured = true;

            await Vue.prototype.$logStoreService.error(`${logAnnexeMessage} - ERROR`, error, 'SynchronizationService');
          });
      }
    }
  }
}
